<template>
<!-- eslint-disable --> 
    <div class="container">
        <div class="row text-center">
            <div class="col-md-2"></div>
            <div class="col-md-8">
                <h4>Your quiz summary</h4>
            </div>
            <div class="col-md-2"></div>
        </div>
        <br><br>
        <div v-for="tune in tunes">
            <div class="row" >
                <div class="col-md-3"></div>
                <div class="col-6 col-md-3 text-center">
                    <h6>{{ tune.title }}</h6>
                    <p>{{ tune.author }} <br>
                    {{ tune.sample }}</p>
                </div>
                <div class="col-6 col-md-3">
                    Your mark:
                    <ul class="ratings-small">
                        <li class="star-small" v-bind:class="{ selected: ratedTunes[tune.id] == 5}"></li>
                        <li class="star-small" v-bind:class="{ selected: ratedTunes[tune.id] == 4}"></li>
                        <li class="star-small" v-bind:class="{ selected: ratedTunes[tune.id] == 3}"></li>
                        <li class="star-small" v-bind:class="{ selected: ratedTunes[tune.id] == 2}"></li>
                        <li class="star-small" v-bind:class="{ selected: ratedTunes[tune.id] == 1}"></li>
                    </ul>
                </div>
                <div class="col-md-3"></div>
            </div>
            <div class="row">
                <div class="col-1"></div>
                <div class="col-10 text-center">
                    <h6 v-if="!tune.human">This musical phrase was generated by a machine!</h6>
                    <h6 v-if="tune.human">This musical phrase was part of an original solo!</h6>
                </div>
                <div class="col-1"></div>
            </div>
            <br>
            <div class="row">
                <div class="col-md-3 col-1"></div>
                    <div class="col-md-6 col-10">
                        <hr>
                    </div>
                <div class="col-md-3 col-1">
                </div>
            </div>
            <br>
        </div>
        <div class="row text-center">
            <div class="col-md-3 col-1"></div>
                <div class="col-md-6 col-10 text-secondary">
                    <h6>Thank you for your contribution to our research. 
                    The results will be available at 
                    <a href="https://github.com/vincenzomadaghiele/MINGUS " target="_blank" rel="noopener noreferrer">the project webpage</a> 
                    as soon as they are ready.</h6>
                    <br><br>
                </div>
            <div class="col-md-3 col-1">
        </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

export default {
  data() {
    return {
      tunes: [
        {
          title: null,
          author: null,
          sample: null,
        },
      ],
      ratedTunes: {
        id: null,
      },
    };
  },
  methods: {
    getData() {
      const retrievedTunes = localStorage.getItem('tunes');
      const tunes = JSON.parse(retrievedTunes);
      this.tunes = tunes;
      const retrievedRatings = localStorage.getItem('rated-tunes');
      const ratedTunes = JSON.parse(retrievedRatings);
      this.ratedTunes = ratedTunes;
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style>
.ratings-small {
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;
  direction: rtl;
  text-align: left;
}

.star-small {
  position: relative;
  line-height: 20px;
  display: inline-block;
  transition: color 0.2s ease;
  color: #ebebeb;
}

.star-small:before {
  content: '\2605';
  width: 20px;
  height: 20px;
  font-size: 20px;
}

.star-small.selected,
.star-small.selected ~ .star-small{
  transition: color 0.8s ease;
  color: black;
}
</style>
